import React, { useEffect } from 'react';
import { useInView } from 'react-intersection-observer';
import { motion, useAnimation } from 'framer-motion';

import { fadeIn, slideUp } from './indexVariants';
import * as welcomeStyles from './welcomebio.module.scss';


 const Bio = () =>  {
  const [ref, isVisible] = useInView({ threshold: 0.6 });
  const controls = useAnimation();
// styles


  useEffect(() => {
    if(isVisible)
      controls.start('animate');
  }, [isVisible, controls])

  return (
  	<section ref={ref}  className={welcomeStyles.backgroundbio}>
      <section ref={ref}  className={welcomeStyles.effectcolor}>
   	  <section ref={ref}  className={welcomeStyles.container}>
       <h1>Bio</h1>
        
       <div className={welcomeStyles.content}>
         <div class="row">
           <div class="col-md-5 col-sx-12"></div>
           <div class="col-md-7 col-sx-12">
           
              <p>Since 1980, Alec Baldwin has appeared in numerous productions on stage, in films and on television. He received a Tony nomination (A Streetcar Named Desire, 1992) an Oscar nomination (The Cooler, 2004) and has won three Emmy awards, three Golden Globes and seven consecutive Screen Actors Guild Awards for Best Actor in a Comedy Series for his role on NBC-TV's 30 Rock, making him the actor with the most SAG Awards of all time. His films include MIAMI BLUES, THE HUNT FOR RED OCTOBER, GLENGARRY GLEN ROSS, MALICE, THE EDGE, IT'S COMPLICATED, BLUE JASMINE, STILL ALICE, MISSION IMPOSSIBLE: ROGUE NATION, and THE BOSS BABY among many others.</p>
              <p>Baldwin earned a Bachelor of Fine Arts degree from New York University’s Tisch School of the Arts in 1994 and has received honorary doctorates from NYU (2010) and the Manhattan School of Music (2012). He has served on numerous boards related to the arts, the environment and public policy including the Hamptons International Film Festival, Bay Street Theater, The Actors Studio, and the Roundabout Theatre Company. He currently serves on the board of People for the American Way and the New York Philharmonic.</p>
              <p>He has authored three books: A PROMISE TO OURSELVES, his memoir entitled NEVERTHELESS and, with Kurt Andersen, the Donald Trump parody YOU CAN’T SPELL AMERICA WITHOUT ME.
He is the host of a podcast, HERE’S THE THING, for iHeartRadio. 
</p>
              <p>Baldwin is married to author and wellness expert Hilaria Thomas Baldwin. They have six children: Carmen, Rafael, Leonardo, Romeo, Eduardo, and Lucia, as well as his eldest, Ireland Baldwin. Hilaria and Alec oversee The Hilaria and Alec Baldwin Foundation, which focuses on funding the arts.</p>
           </div>
         </div>
       
        
      </div>
      </section>
      </section>
	</section>
	 
  )
}

export default Bio;
