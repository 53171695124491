import React, { useState } from 'react';

import Hero from '../components/index/Hero';
import Seo from '../components/utilities/Seo';
import Navbar from '../components/utilities/Navbarbio';
import WelcomeBio from '../components/index/Bio';
import Menu from '../components/menu/Menu';

//import '../../node_modules/bootstrap/dist/css/bootstrap.css'; // Archivo CSS de Bootstrap 4 
//import '../../node_modules/bootstrap/dist/js/bootstrap.min.js'; 

import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/css/bootstrap.min.css'; 
/*
global.jQuery = require('jquery');
require('bootstrap');
*/

const BioPage = ()  => {

  return ( 
      <>  
		<Menu bgcolor='navbar navbar-expand-md navbar-dark fixed-top bg-blue'/>
		<WelcomeBio />
      </>
  )
} 

export default BioPage;